import request from "@/api/request"


/**
 * 查询考卷
 *
 * @author vigour
 * @date 2022-07-22 17:09:36
 */
 export function getExamDetail (parameter) {
  return request({
    url: '/course/jcExam/detail',
    method: 'get',
    params: parameter
  })
}


/**
 * 查询课题列表
 *
 * @author vigour
 * @date 2022-07-22 17:09:36
 */
 export function getExamTitleInfoByExamId (parameter) {
  return request({
    url: '/course/jcExamTitle/getExamTitleInfoByExamId',
    method: 'get',
    params: parameter
  })
}

export function getPage (parameter) {
  return request({
    url: '/course/jcExamResult/page',
    method: 'get',
    params: parameter
  })
}


// 提交考试分数
export function jcExamResultAdd(data) {
  return request({
    method: 'post',
    url: '/course/jcExamResult/add',
    data:data
  })
}

//考试名称
export function jcExamResultPageInfo(parameter)  {
  return request({
    method: 'get',
    url: '/course/jcExamResult/pageInfo',
    params: parameter
  })
}
